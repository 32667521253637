import { IconSizeEnum } from "@config/icons";
import { useTranslation } from "@translations/use-translation";
import { FC, PropsWithChildren } from "react";
import { ChipRemoveIcon, ChipRoot, ChipText } from "./styles";
import { ChipProps } from "./types";

export const Chip: FC<PropsWithChildren<ChipProps>> = (props) => {
    const { t } = useTranslation();
    const { children, kind, onRemove, size = "small", variant = "primary", ...restProps } = props;

    return (
        <ChipRoot kind={kind} size={size} variant={variant} {...restProps}>
            <ChipText>{children}</ChipText>
            {!!onRemove && (
                <ChipRemoveIcon
                    aria-label={t("common:actions.remove")}
                    name="cross"
                    onClick={onRemove}
                    role="button"
                    size={IconSizeEnum.small}
                    tabIndex={0}
                />
            )}
        </ChipRoot>
    );
};
