import { margins, truncate } from "@styles/mixins";
import { IIconProps, Icon } from "@ui/Icon";
import { CssHelper } from "@utils/CssHelper";
import rem from "polished/lib/helpers/rem";
import { HTMLAttributes } from "react";
import styled, { css } from "styled-components";
import { ChipRootProps } from "./types";

export const ChipText = styled("span")<HTMLAttributes<HTMLSpanElement>>``;

export const ChipRemoveIcon = styled(Icon)<IIconProps>``;

export const ChipRoot = styled("div")<ChipRootProps>(
    ({ kind, size, variant, theme, mb, ml, mr, mt }) => css`
        && {
            align-items: center;
            background-color: ${kind === "muted" && variant
                ? theme.color.button.muted.background(variant)[0]
                : variant
                  ? theme.color.button.default.background(variant)[0]
                  : null};
            border-radius: 999px;
            color: ${variant === "tertiary"
                ? theme.color.palette.text
                : kind === "muted" && variant
                  ? theme.color.button.muted.text(variant)
                  : variant
                    ? theme.color.button.default.text
                    : null};
            display: flex;
            font-size: ${rem(theme.typography.variant.caption.fontSize)};
            font-weight: ${theme.typography.fontWeight.medium};
            height: ${size ? rem(theme.size.textBadge[size]) : null};
            justify-content: center;
            letter-spacing: ${theme.typography.letterSpacing};
            line-height: ${rem(theme.typography.variant.caption.lineHeight)};
            max-width: 100%;
            padding: ${rem(4)} ${rem(8)};
            position: relative;

            ${margins({ mb, ml, mr, mt }, theme)};

            ${ChipRemoveIcon} {
                flex: 0 0 auto;
                height: ${rem(12)};
                margin-left: ${rem(4)};
                opacity: 0.5;
                transition: ${CssHelper.transition("opacity")};
                width: ${rem(12)};

                &:active,
                &:hover {
                    opacity: 1;
                }
            }

            ${ChipText} {
                display: inline-block;
                flex: 0 1 auto;
                min-width: 0;
                ${truncate};
            }
        }
    `,
);
