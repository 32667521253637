import { DISABLED_OPACITY } from "@styles/constants";
import { ITheme } from "@styles/theme";
import { withUnit } from "@uxf_base/utils/styling";
import tint from "polished/lib/color/tint";
import rem from "polished/lib/helpers/rem";
import { HTMLAttributes } from "react";
import styled, { css } from "styled-components";

export const StyledInputWrapper = styled("div")<HTMLAttributes<HTMLDivElement>>(
    ({ theme }) => css`
        color: ${theme.color.palette.text};
        min-height: ${rem(theme.size.input.small)};
        letter-spacing: ${theme.typography.letterSpacing};
        position: relative;

        &[aria-readonly="true"] {
            pointer-events: none;
        }

        &[aria-disabled="true"] {
            opacity: ${DISABLED_OPACITY};
            pointer-events: none;
        }
    `,
);

export const StyledSingleValueContainer = styled("div")<HTMLAttributes<HTMLDivElement>>`
    -webkit-overflow-scrolling: touch;
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex: 1 1 auto;
    overflow: hidden;
    position: relative;
`;

export const StyledMultiValueContainer = styled("div")<HTMLAttributes<HTMLDivElement>>`
    -webkit-overflow-scrolling: touch;
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex: 1 1 auto;
    flex-wrap: wrap;
    margin-right: ${rem(-4)};
    overflow: hidden;
    padding-bottom: ${rem(2)};
    padding-top: ${rem(2)};
    position: relative;
`;

export const StyledPlaceholder = styled("div")<HTMLAttributes<HTMLDivElement>>(
    ({ theme }) => css`
        color: ${theme.color.palette.textMutedLighter};
        overflow: hidden;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        white-space: nowrap;
    `,
);

export const StyledSingleValue = styled("div")<HTMLAttributes<HTMLDivElement>>`
    box-sizing: border-box;
    color: inherit;
    flex: 1 1 100%;
    overflow: hidden;
    position: absolute;
    text-overflow: ellipsis;
    top: 50%;
    transform: translateY(-50%);
    white-space: nowrap;
`;

const optionStyle = (theme: ITheme) => css`
    align-items: center;
    display: flex;
    font-size: ${rem(theme.typography.variant.medium.fontSize)};
    font-weight: ${theme.typography.fontWeight.medium};
    justify-content: flex-start;
    line-height: ${rem(theme.typography.variant.medium.lineHeight)};
    list-style: none;
    min-width: 0;
    overflow: hidden;
    padding: ${rem(theme.legacySpacing(0.5))} ${rem(theme.legacySpacing(1.5))};
    width: 100%;
`;

export const StyledOption = styled("div")<HTMLAttributes<HTMLDivElement>>(
    ({ theme }) => css`
        ${optionStyle(theme)};
        color: ${theme.color.palette.text};
        min-height: ${rem(theme.size.dropdown + theme.border.thick)};
        padding-bottom: ${rem(theme.legacySpacing(0.5))};
        padding-top: ${rem(theme.legacySpacing(0.5))};
        text-align: left;

        & + * {
            border-top: ${withUnit(theme.border.thick, "px")} solid ${theme.color.palette.bgLight};
        }

        &:first-child {
            border-top-left-radius: inherit;
            border-top-right-radius: inherit;
            min-height: ${rem(theme.size.dropdown)};
        }

        &:last-child {
            border-bottom-left-radius: inherit;
            border-bottom-right-radius: inherit;
        }

        &.option--is-selected {
            background-color: ${tint(0.9, theme.color.palette.secondary)};
        }

        &.option--is-focused,
        &:hover,
        &:active {
            background-color: ${theme.color.palette.secondary};
            color: ${theme.color.palette.white};
        }

        &.option--is-disabled {
            opacity: ${DISABLED_OPACITY};
            pointer-events: none;
        }
    `,
);

export const StyledNoOptionsMessage = styled("div")<HTMLAttributes<HTMLDivElement>>(
    ({ theme }) => css`
        ${optionStyle(theme)};
        border-radius: inherit;
        color: ${theme.color.palette.textMuted};
        height: ${rem(theme.size.dropdown)};
    `,
);
