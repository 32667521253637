import { isBrowser } from "@uxf_base/utils/isBrowser";
import clsx from "clsx";
import { Property } from "csstype";
import rem from "polished/lib/helpers/rem";
import { forwardRef, ForwardRefRenderFunction, useEffect } from "react";
import { Styles } from "react-select";
import Async from "react-select/async";
import { useTheme } from "styled-components";
import { CustomReactSelectComponents } from "./components";
import { IAutoCompleteProps } from "./types";

const customComponents = {
    ClearIndicator: CustomReactSelectComponents.ClearIndicator,
    DropdownIndicator: CustomReactSelectComponents.DropdownIndicator,
    IndicatorSeparator: CustomReactSelectComponents.IndicatorSeparator,
    LoadingIndicator: CustomReactSelectComponents.LoadingIndicator,
    NoOptionsMessage: CustomReactSelectComponents.NoOptionsMessage,
    Option: CustomReactSelectComponents.Option,
    Placeholder: CustomReactSelectComponents.Placeholder,
    SelectContainer: CustomReactSelectComponents.SelectContainer,
    SingleValue: CustomReactSelectComponents.SingleValue,
    ValueContainer: CustomReactSelectComponents.SingleValueContainer,
};

const AutoCompleteRenderFn: ForwardRefRenderFunction<any, IAutoCompleteProps> = (props, ref) => {
    const customTheme = useTheme();

    const {
        autoComplete,
        className,
        components,
        id,
        inputId,
        errorMessage,
        invalid,
        isDisabled,
        menuPortal,
        menuStyles,
        name,
        onChange,
        readonly,
        showOptionWithColor,
        showOptionWithLogo,
        wrapperProps,
        ...restProps
    } = props;

    const styles: Partial<Styles> = {
        control: () => ({
            alignItems: "center",
            display: "flex",
            height: "inherit",
            position: "relative" as Property.Position,
        }),
        input: (provided) => ({
            ...provided,
            margin: 0,
        }),
        menu: (provided) =>
            ({
                ...provided,
                backgroundColor: customTheme.color.palette.white,
                borderRadius: rem(customTheme.borderRadius.default),
                boxShadow: customTheme.shadow.dropdown,
                margin: `0 0 ${rem(customTheme.legacySpacing(2))}`,
                padding: 0,
                width: rem(250),
                zIndex: customTheme.zIndex.dropdown,
                ...menuStyles,
            }) as any,
        menuList: (provided) => ({
            ...provided,
            borderRadius: "inherit",
            padding: 0,
        }),
        menuPortal: (provided) => ({ ...provided, zIndex: customTheme.zIndex.dropdown }),
    };

    useEffect(() => {
        /* this is because now there is no autoComplete html property in react-select
         * TODO: refactor after implementation of https://github.com/JedWatson/react-select/pull/2395 */
        if (isBrowser && id && autoComplete) {
            document.getElementById(id)?.setAttribute("autocomplete", autoComplete);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Async
            aria-disabled={isDisabled}
            className={clsx("autocomplete", className)}
            classNamePrefix="autocomplete"
            components={{
                ...customComponents,
                ...(showOptionWithColor
                    ? {
                          Option: CustomReactSelectComponents.OptionWithColor,
                      }
                    : {}),
                ...(showOptionWithLogo
                    ? {
                          Option: CustomReactSelectComponents.OptionWithLogo,
                      }
                    : {}),
                ...components,
            }}
            id={wrapperProps?.id}
            inputId={id || `react-select-${name}` || inputId}
            isDisabled={isDisabled}
            menuPortalTarget={menuPortal ? (typeof window !== "undefined" ? document.body : null) : null}
            name={name}
            onChange={onChange}
            ref={ref}
            styles={styles}
            tabIndex={props.isDisabled ? "-1" : props.tabIndex}
            wrapperProps={{
                "aria-disabled": isDisabled,
                "aria-invalid": invalid,
                "aria-readonly": readonly,
                "aria-errormessage": errorMessage,
                ...wrapperProps,
            }}
            {...restProps}
        />
    );
};

export const AutoComplete = forwardRef<any, IAutoCompleteProps>(AutoCompleteRenderFn);

AutoComplete.defaultProps = {
    isClearable: true,
    loadingMessage: () => "Načítám...",
    menuPlacement: "bottom",
    menuPortal: true,
    menuShouldScrollIntoView: true,
    noOptionsMessage: () => "Zadejte hledaný text...",
    placeholder: "Vyberte...",
};

AutoComplete.displayName = "AutoComplete";
