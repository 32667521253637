import { IconSizeEnum } from "@config/icons";
import { useTranslation } from "@translations/use-translation";
import { Avatar } from "@ui/Avatar";
import { Chip } from "@ui/Chip/Chip";
import { FlexBox } from "@ui/FlexBox";
import { Icon } from "@ui/Icon";
import { Span, Truncate } from "@ui/Text";
import { isNotNil } from "@uxf/core/utils/is-not-nil";
import clsx from "clsx";
import { CSSProperties, ComponentType } from "react";
import {
    IndicatorProps,
    MenuListComponentProps,
    MultiValueProps,
    OptionProps,
    PlaceholderProps,
    SingleValueProps,
    components,
} from "react-select";
import { NoticeProps } from "react-select/src/components/Menu";
import { useTheme } from "styled-components";
import {
    StyledInputWrapper,
    StyledMultiValueContainer,
    StyledNoOptionsMessage,
    StyledOption,
    StyledPlaceholder,
    StyledSingleValue,
    StyledSingleValueContainer,
} from "./styles";
import { ReactSelectInnerRef, SelectOption } from "./types";

function SelectContainer({ cx, ...props }: any) {
    return (
        <StyledInputWrapper
            className={cx(props.innerProps.className, props.className)}
            {...props.selectProps.wrapperProps}
            {...props.innerProps}
        >
            {props.children}
        </StyledInputWrapper>
    );
}

const Placeholder: ComponentType<PlaceholderProps<SelectOption>> = (props) => {
    return (
        <StyledPlaceholder className={`${props.selectProps.classNamePrefix}__placeholder`} {...props.innerProps}>
            {props.children}
        </StyledPlaceholder>
    );
};

function SingleValueContainer(props: any) {
    return (
        <StyledSingleValueContainer
            className={`${props.selectProps.classNamePrefix}__value-container`}
            {...props.innerProps}
        >
            {props.children}
        </StyledSingleValueContainer>
    );
}

function MultiValueContainer(props: any) {
    return (
        <StyledMultiValueContainer
            className={`${props.selectProps.classNamePrefix}__value-container`}
            {...props.innerProps}
        >
            {props.children}
        </StyledMultiValueContainer>
    );
}

const SingleValue: ComponentType<SingleValueProps<SelectOption>> = (props) => {
    return (
        <StyledSingleValue className={`${props.selectProps.classNamePrefix}__single-value`} {...props.innerProps}>
            {props.children}
        </StyledSingleValue>
    );
};

const MultiValue: ComponentType<MultiValueProps<SelectOption>> = (props) => {
    return (
        <Chip
            className={`${props.selectProps.classNamePrefix}__multi-value`}
            kind="muted"
            onRemove={props.removeProps.onClick}
            size="large"
            variant="text"
            mb={2}
            mt={2}
            mr={4}
            {...props.innerProps}
        >
            {props.children}
        </Chip>
    );
};

const Option: ComponentType<OptionProps<SelectOption> & ReactSelectInnerRef> = ({ innerRef, ...props }) => {
    return (
        <StyledOption
            className={clsx(
                "option",
                props.isDisabled && "option--is-disabled",
                props.isFocused && "option--is-focused",
                props.isSelected && "option--is-selected",
            )}
            ref={innerRef}
            role="listitem"
            {...props.innerProps}
        >
            {props.children}
        </StyledOption>
    );
};

const OptionWithColor: ComponentType<OptionProps<SelectOption> & ReactSelectInnerRef> = ({ innerRef, ...props }) => {
    const data = props.data as SelectOption;
    const theme = useTheme();

    return (
        <StyledOption
            className={clsx(
                "option",
                props.isDisabled && "option--is-disabled",
                props.isFocused && "option--is-focused",
                props.isSelected && "option--is-selected",
            )}
            ref={innerRef}
            role="listitem"
            {...props.innerProps}
        >
            <FlexBox alignItems="center">
                {isNotNil(data?.color) && (
                    <div
                        className="size-4 bg-[var(--bg-color)] mr-2 rounded-full"
                        style={{ ["--bg-color"]: theme.color.palette[data.color] } as CSSProperties}
                    />
                )}
                <Span truncate>{props.children}</Span>
            </FlexBox>
        </StyledOption>
    );
};

const OptionWithLogo: ComponentType<OptionProps<SelectOption> & ReactSelectInnerRef> = ({ innerRef, ...props }) => {
    const data = props.data as SelectOption;
    return (
        <StyledOption
            className={clsx(
                "option",
                props.isDisabled && "option--is-disabled",
                props.isFocused && "option--is-focused",
                props.isSelected && "option--is-selected",
            )}
            ref={innerRef}
            role="listitem"
            {...props.innerProps}
        >
            <FlexBox alignItems="center">
                {!!data?.image && (
                    <Avatar src={data.image} size="size40" squircle={data?.squircle} objectFit="contain" mr={8} />
                )}
                <Span truncate>{props.children}</Span>
            </FlexBox>
        </StyledOption>
    );
};

const NoOptionsMessage: ComponentType<NoticeProps<SelectOption> & ReactSelectInnerRef> = ({ children, ...props }) => {
    return (
        <StyledNoOptionsMessage
            className={`${props.selectProps.classNamePrefix}__no-options-message`}
            {...props.innerProps}
        >
            <Truncate>{children}</Truncate>
        </StyledNoOptionsMessage>
    );
};

const MenuList: ComponentType<MenuListComponentProps<SelectOption> & ReactSelectInnerRef & { role?: string }> = (
    props,
) => <components.MenuList role="list" {...props} />;

function ClearIndicator() {
    return null;
}
function IndicatorSeparator() {
    return null;
}
function LoadingIndicator() {
    return null;
}

const DropdownIndicator: ComponentType<IndicatorProps<SelectOption> & ReactSelectInnerRef> = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    // tslint:disable-next-line:no-duplicate-string
    return props.selectProps["aria-readonly"] ? null : (
        <Icon
            aria-label={t("common:actions.open")}
            className={`${props.selectProps.classNamePrefix}__dropdown-indicator`}
            color={theme.color.palette.textMuted}
            ml={8}
            name="triangleDown"
            size={IconSizeEnum.large}
            {...props.innerProps}
        />
    );
};

export const CustomReactSelectComponents = {
    ClearIndicator,
    DropdownIndicator,
    IndicatorSeparator,
    LoadingIndicator,
    MenuList,
    NoOptionsMessage,
    Option,
    OptionWithColor,
    OptionWithLogo,
    Placeholder,
    SelectContainer,
    MultiValue,
    SingleValue,
    MultiValueContainer,
    SingleValueContainer,
};
